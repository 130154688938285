<template>
  <div id="app">
    <nav class="navbar is-fixed-top">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img :src="require('./assets/Brand/ctg.svg')" alt="CTG Logo" />
        </a>
        <!--
    Using the v-on: directive to listen for the click event and toggle the data property showNav. Also, using the v-bind: directive to reactively update the class attribute 'is-active' based on the showNav property.
    -->
        <div
          class="navbar-burger is-large"
          @click="showNav = !showNav"
          :class="{ 'is-active': showNav }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>
      <!--
    Using the v-bind: directive to reactively update the class attribute 'is-active' based on the showNav property.
    -->
      <div class="navbar-menu" :class="{ 'is-active': showNav }">
        <div
          class="
            navbar-end
            is-justify-content-center
            is-align-content-center
            is-uppercase
            is-size-6
          "
          @click="showNav = false"
        >
          <a class="navbar-item" href="#Benefit"> benefits </a>
          <a class="navbar-item" href="#MedRhein"> medrhein </a>
          <!-- <a class="navbar-item" href="/blog"> Blog </a> -->
          <a
            class="navbar-item"
            href="mailto:service@ctgtrading.de?subject=Contact%3A%20A%20Message%20to%20CTG%20China%20Trading%20GmbH&body=Dear%20Colleagues%20in%20CTG%20China%20Trading%20GmbH%2C%0A%0AName%3A%0APhone%3A%0ACompany%3A%0AEmail%3A%0AMessage%3A"
            ><button
              class="
                button
                is-primary is-uppercase is-rounded is-size-7
                contact_btn
              "
            >
              contact us!
            </button></a
          >
        </div>
      </div>
    </nav>
    <main>
      <section id="Sec_CTG" class="section section_imgbg">
        <h1
          class="title is-uppercase is-weight-bold is-size-1 is-size-3-mobile"
        >
          CTG Trading GmbH
        </h1>
        <h2 class="subtitle is-weight-medium is-size-3 is-size-5-mobile">
          The one-stop solution importeur.
        </h2>
      </section>
      <section id="Sec_Intro" class="section">
        <p class="has-text-weight-semibold is-size-4 is-size-5-mobile">
          The CTG Trading GmbH was founded in 1993 in Germany. We are
          highly skilled in the import and distribution of medical products from
          Asia to the european market regarding the latest laws and regulations
          in the European Union.
        </p>
        <p class="is-size-4 is-size-5-mobile">
          Our international team of 50 emplayees and partners in Germany and
          China is constantly working on the further development of the company
          and could thus ensure a turnover of over 50 million EUR in 2021.
        </p>
        <a
          class="button is-primary is-uppercase is-rounded contact_btn"
          href="mailto:service@ctgtrading.de?subject=Contact%3A%20A%20Message%20to%20CTG%20China%20Trading%20GmbH&body=Dear%20Colleagues%20in%20CTG%20China%20Trading%20GmbH%2C%0A%0AName%3A%0APhone%3A%0ACompany%3A%0AEmail%3A%0AMessage%3A"
        >
          contact us!</a
        >
      </section>
      <!--
      <section id="Sec_Team" class="section">
        <div class="content team_container">
          <img :src="require('./assets/Images/team.jpg')" alt="team">
          <div class="text_container">
            <h3 class="title team_text_one is-size-4-mobile">&#10074; Creative</h3>
            <h3 class="title team_text_two  is-size-4-mobile">&#10074; Young</h3>
            <h3 class="title team_text_three  is-size-4-mobile">&#10074; Professional</h3>
          </div>
        </div>
      </section>
      -->
      <section id="Sec_Benefit" class="section bg_transblue">
        <div
          id="Benefit"
          style="position: relative; top: calc(-76px - 3em)"
          class="anchor-offset"
        ></div>
        <div class="bentext_container">
          <div class="bentext_left">
            <h1 class="title is-uppercase is-weight-bold">BenEFITS</h1>
            <h3 class="title is-uppercase is-size-4 is-weight-medium">
              working with CTG
            </h3>
          </div>
          <div class="bentext_right">
            <p class="content is-weight-semibold is-size-5 bolder">
              We help you sell and optimize your products fitting to the complex
              and diverse european market.
            </p>
            <p class="content is-size-5">
              In partnership with you as a manufacturer we work at every point
              of the product development life cycle. We offer our support
              regarding any regulatory topics, import or certificate issues. We
              offer individual solutions for entering the European market.
            </p>
          </div>
        </div>
        <img :src="require('./assets/process.svg')" alt="lifecycle" />
        <div class="tabs is-medium is-fullwidth">
          <ul class="tab_ul">
            <li
              :class="active_tab == 0 ? 'is-active' : 'not-active'"
              @click="active_tab = 0"
            >
              <a>Concepts</a>
            </li>
            <li
              :class="active_tab == 1 ? 'is-active' : 'not-active'"
              @click="active_tab = 1"
            >
              <a>Planning</a>
            </li>
            <li
              :class="active_tab == 2 ? 'is-active' : 'not-active'"
              @click="active_tab = 2"
            >
              <a>Design</a>
            </li>
            <li
              :class="active_tab == 3 ? 'is-active' : 'not-active'"
              @click="active_tab = 3"
            >
              <a>Validation</a>
            </li>
            <li
              :class="active_tab == 4 ? 'is-active' : 'not-active'"
              @click="active_tab = 4"
            >
              <a>Launch</a>
            </li>
            <li
              :class="active_tab == 5 ? 'is-active' : 'not-active'"
              @click="active_tab = 5"
            >
              <a>Post market</a>
            </li>
          </ul>
        </div>
        <div class="tab_content">
          <div class="content" v-if="active_tab == 0">
            We support you with consulting during the initial evaluation of the
            possible development of commercial products regarding laws,
            regulations, norms and needs in the eurpoean market.
          </div>
          <div class="content" v-if="active_tab == 1">
            <ul>
              <li>
                <h3 class="title is-weight-medium is-size-5">Plan and Assistance for the Certification Process</h3>
                <img
                  :src="require('./assets/ce-norm.svg')"
                  alt="ce certifacate"
                />
              </li>
              <li>
                <h3 class="title is-weight-medium is-size-5">Performance Evaluation Plan (PEP)</h3>
                <p>
                  Performance evaluation for in vitro diagnostic medical devices
                  is an important process and serves to meet the Essential
                  Safety and Performance Requirements of IVDR EU 2017/746.
                </p>
              </li>
            </ul>
          </div>
          <div class="content" v-if="active_tab == 2">
            We provide support during the development of the product design and
            of manufacturing process, verification or validation.
          </div>
          <div class="content" v-if="active_tab == 3">
            The knowledge gained from analytical performance studies or (valid)
            scientific literature about aspects of clinical and analytical
            performance of the IVD and, if applicable, clinical performance
            studies are summarized in a performance evaluation report.
          </div>
          <div class="content" v-if="active_tab == 4">
            We make sure that every distribution and marketing channel is
            optimally tuned for the launch of your product.
          </div>
          <div class="content" v-if="active_tab == 5">
            <ul>
              <li>
                Part B of the new IVDR Annex XIII deals with the post-market
                phase and describes the requirements for tracking post-market
                performance.
              </li>
              <li>
                PMPF (post-market performance follow-up) refers to the concept
                that you must continuously review the performance evaluation
                report to ensure that it reflects the current state of the art.
              </li>
              <li>
                We help you to design and implement the PMPF Plan successfully.
                This will benefit the product competitiveness immensely.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section id="Sec_Interest" class="section bg_primary">
        <h1
          class="
            title
            has-text-white
            is-uppercase is-weight-bold is-size-1 is-size-3-mobile
          "
        >
          interested?
        </h1>
        <a
          class="
            button
            is-primary is-uppercase is-rounded
            contact_btn
            btn_outline
          "
          href="mailto:service@ctgtrading.de?subject=Contact%3A%20A%20Message%20to%20CTG%20China%20Trading%20GmbH&body=Dear%20Colleagues%20in%20CTG%20China%20Trading%20GmbH%2C%0A%0AName%3A%0APhone%3A%0ACompany%3A%0AEmail%3A%0AMessage%3A"
        >
          contact us!</a
        >
      </section>
      <section id="Sec_Ability" class="section">
        <h2 class="title is-uppercase is-weight-medium is-size-4">
          what we can do for you
        </h2>
        <div class="card_container">
          <div
            v-for="aspect in aspects.ability"
            :key="aspect.name"
            class="card"
          >
            <div class="card-image">
              <img
                :src="require('./assets/Icons/Icon--' + aspect.name + '.svg')"
                :alt="aspect.name"
                class="icon_dark"
              />
            </div>
            <header class="card-header">
              <h3 class="card-header-title is-weight-semibold is-size-5">
                {{ aspect.title }}
              </h3>
            </header>
            <div class="content">
              <p class="is-size-5">
                {{ aspect.content }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="Sec_Partner" class="section bg_transblue">
        <a v-for="partner in partners" :key="partner.name" :href="partner.url">
          <img
            :src="require('./assets/Partners/' + partner.name + '-Logo.svg')"
            :alt="partner.name"
          />
        </a>
      </section>
      <section id="Sec_MedRhein" class="section section_imgbg">
        <div
          id="MedRhein"
          style="position: relative; top: calc(-76px - 3em)"
          class="anchor-offset"
        ></div>
        <div @click="openMedRhein()" class="div_link">
          <img
            :src="require('./assets/Brand/medrhein_white.svg')"
            alt="MedRhein Logo"
          />
          <p class="subtitle is-weight-medium is-size-4 is-size-5-mobile">
            During the pandemic there was a very high demand of medical masks
            and later on covid-19 antigen test kits in all over europe. As an
            answer to those products in need, MedRhein was founded focusing on
            the import and distribution of medical equipment to overcome the
            crisis as soon as possible.
          </p>
        </div>
      </section>
      <section id="Sec_TestKit" class="section">
        <div class="testkit_container">
          <img
            :src="require('./assets/Images/testkit--1.jpg')"
            alt="test stick"
            class="teststick_img"
          />
          <div class="content_container">
            <p class="content is-weight-normal is-size-4">
              During a very short period of time a team of highly skilled
              experts was built and started beginning their work.
            </p>
            <p class="content is-size-4 bolder-text">
              They established the brand MedRhein to market and distribute high
              quality tests for tests centres and the every day use of end
              customer from diverse manufacterers to satisfy the markets demand.
            </p>
            <p class="content is-weight-normal is-size-4">
              Our successfully existing network of logistics experts was an
              enormous advantage during that period of time.
            </p>
          </div>
          <img
            :src="require('./assets/Images/testkit--2.jpg')"
            alt="test kit"
            class="testkit_img"
          />
        </div>
      </section>
      <section id="Sec_Social" class="section">
        <a href="https://www.linkedin.com/company/medrhein/">
          <img
            :src="require('./assets/Images/socialmedia.jpg')"
            alt="social media"
          />
        </a>
      </section>
      <section id="Sec_Focuse" class="section">
        <h2 class="title is-uppercase is-weight-medium is-size-4">
          our main focus
        </h2>
        <div class="card_container">
          <div v-for="aspect in aspects.focus" :key="aspect.name" class="card">
            <div class="card-image">
              <img
                :src="require('./assets/Icons/Icon--' + aspect.name + '.svg')"
                :alt="aspect.name"
                class="icon_light"
              />
            </div>
            <header class="card-header">
              <h3 class="card-header-title is-weight-semibold is-size-5">
                {{ aspect.title }}
              </h3>
            </header>
            <div class="content">
              <p class="is-size-5">
                {{ aspect.content }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="Sec_PreFooter" class="section bg_transblue">
        <h1
          class="title is-uppercase is-weight-bold is-size-3 is-size-3-mobile"
        >
          You’ve Got Something Similar in Mind?
        </h1>
        <h2 class="subtitle is-weight-medium is-size-4 is-size-5-mobile">
          We will find a customized solution forimporting your product.
        </h2>
        <a
          class="
            button
            is-primary is-uppercase is-rounded
            contact_btn
            btn_outline
          "
          href="mailto:service@ctgtrading.de?subject=Contact%3A%20A%20Message%20to%20CTG%20China%20Trading%20GmbH&body=Dear%20Colleagues%20in%20CTG%20China%20Trading%20GmbH%2C%0A%0AName%3A%0APhone%3A%0ACompany%3A%0AEmail%3A%0AMessage%3A"
        >
          contact us!</a
        >
      </section>
    </main>
    <footer class="footer is-size-6">
      <div class="content">
        <label>©2021 CTG Trading GmbH. Alle Rechte vorbehalten.</label>
        <a href="https://www.medrhein.com/help/help7.html">Impressum</a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    openMedRhein() {
      window.open("https://www.medrhein.com/");
    },
  },
  data: function () {
    return {
      active_tab: 0,
      showNav: false,
      partners: [
        {
          name: "SF-express",
          url: "https://www.sf-express.com/",
        },
        {
          name: "DHL",
          url: "https://www.dhl.de/",
        },
        {
          name: "wantai",
          url: "https://www.ystwt.cn/",
        },
        {
          name: "Hotgen",
          url: "http://www.hotgen.com.cn/en",
        },
        {
          name: "GreenSpring",
          url: "https://www.lsybt.com/En/",
        },
      ],
      aspects: {
        ability: [
          {
            name: "import",
            title: "Import",
            content:
              "With our already existing infrastructure we support you during the legal process importing productsfrom Asia to the EU.",
          },
          {
            name: "logistics",
            title: "Logistics",
            content:
              "Transporting products highly efficiently and in time to our customers across Europe with a network of reliable partners.",
          },
          {
            name: "sales",
            title: "Sales",
            content:
              "Our Sales Team is highly reliableand consistent in making customers happy and satisfy the demandof the market.",
          },
          {
            name: "marketing",
            title: "Marketing",
            content:
              "Providing material and concepts forour customers to support them market and sell products and winning costumers (B2B).",
          },
          {
            name: "branding",
            title: "Branding/Social Media",
            content:
              "Creating content regularly to growthe brands reach and providing reliable information for our customers(B2B and B2C).",
          },
        ],
        focus: [
          {
            name: "import",
            title: "Import",
            content:
              "High numbers of masks and covid-19 antigen test kits under the latest european restrictions.",
          },
          {
            name: "logistics",
            title: "Logistics",
            content:
              "Transporting over a billion products efficiently and in time to our B2B customers across europe with a network of reliable partners in Europe and China like SF Shunfeng.",
          },
          {
            name: "sales",
            title: "Sales",
            content:
              "Our Sales Team was working consistently with high effort which brought us over 50 million EUR in turnover for 2021.",
          },
          {
            name: "marketing",
            title: "Marketing",
            content:
              "Regular promotion campaigns helped us to steadily increase the market share of MedRhein products.",
          },
          {
            name: "branding",
            title: "Branding/Social Media",
            content:
              "Regular posts and AI-supported ad placement helped us shape MedRhein as a high-quality brand in the medical sector and create an overall very positive image.",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
.title {
  color: $DarkBlue;
}
html {
  scroll-behavior: smooth;
}
#app {
  font-family: $family-serif, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-width: 300px;
  overflow-x: hidden;
}
main {
  margin-top: 76px;
}
nav {
  box-shadow: 0 3px 5px 0 $grey;
}
.navbar-burger {
  background: white !important;
  font-size: 2rem;
  overflow: hidden;
  span {
    height: 0.1rem;
  }
}
.navbar-burger.is-large {
  transform: scale(1.5);
}
.navbar-item {
  font-weight: $weight-normal;
  .button {
    font-weight: $weight-normal;
  }
}
.footer {
  background: white;
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      margin-top: 0.5rem;
      color: $DarkBlue;
    }
  }
}
.navbar-brand {
  padding: 1rem 1rem 1rem 1rem;
}
.section {
  margin: 0 1.5rem;
  // background: teal;
}
.section_imgbg {
  margin-bottom: 3rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1,
  h2,
  p {
    color: white;
  }
}
@media not screen and (min-width: 1024px) {
  .teststick_img {
    display: none;
  }
}


@media screen and (max-width: 520px) {
  .section {
    margin: 0 0;
    margin-bottom: 0 !important;
  }
  .footer {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      a {
        color: $DarkBlue;
      }
    }
  }
  .tabs{
    width: 420px;
  }
  #Sec_Team {
    padding: 2rem !important;
    .team_container{
      .text_container{
        margin-top:1.5rem !important;
        margin-bottom: 0rem !important;
        margin-left: 2rem;
        margin-right: 2rem;
        
      }
    }
    
  }
}
#Sec_CTG {
  background-image: linear-gradient($Blue, $Blue),
    url("~@/assets/Images/CTG_Gebaeude_bearbeitet 1.jpg");
  background-blend-mode: multiply;
  background-size: cover;
  min-height: 43vw;
  // h1{
  //   margin-top:10rem;
  // }
  // h2{
  //   margin-bottom: 10rem;
  // }
}

#Sec_MedRhein {
  background-image: linear-gradient($Blue, $Blue),
    url("~@/assets/Images/MedRhein_Lager 1.jpg");
  background-position: 80% center;
  background-blend-mode: multiply;
  background-size: cover;
  min-height: 43vw;
  p {
    max-width: 800px;
  }
  img {
    padding: 2rem;
    padding-top: 0;
  }
}
#Sec_Intro {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 30vw;
  margin-bottom: 3rem;
  p:first-child {
    margin-top: 0;
  }
  p {
    max-width: 850px;
    text-align: center;
    margin-top: 1.5rem;
  }
  a {
    margin-top: 2rem;
  }
}
.contact_btn {
  padding: 1.2rem 3rem;
  width: 18ch;
}
.bg_transblue {
  background: $TransBlue;
}
.bg_primary {
  background: $primary;
}
.btn_outline {
  border: 2px solid white !important;
}
#Sec_Interest {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
// :target:before {
//   content: " ";
//   display: block;
//   height: 76px;
//   margin: -76px 0 0;
// }
#Sec_Partner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  img {
    max-width: 30vw;
    margin: 1rem;
    max-height: 5rem;
  }
}
.card {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 5vh;
}
.card_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.card-image {
  overflow: hidden;
}
.icon_dark {
  transform: translateX(-100%);
  filter: drop-shadow(65px 0 $DarkBlue);
}
.icon_light {
  transform: translateX(-100%);
  filter: drop-shadow(65px 0 $LightBlue);
}

#Sec_PreFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h1,
  h2 {
    max-width: 800px;
  }
  h2 {
    margin-top: 0.3rem;
  }
}
.div_link {
  cursor: pointer;
}
#Sec_TestKit {
  padding-bottom: 0;
  .testkit_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    .content_container {
      display: flex;
      flex-direction: column;
      max-width: 500px;
      p {
        text-align: left;
      }
      .bolder-text {
        font-weight: 500;
      }
    }
    .testkit_img {
      margin-top: 3rem;
    }
  }
}
#Sec_Benefit {
  display: flex;
  flex-direction: column;
  align-items: center;
  .bentext_container {
    display: flex;
    flex-direction: row;
    max-width: 800px;
    justify-content: space-between;
    flex-wrap: wrap;
    .bentext_left {
      display: flex;
      flex-direction: column;
      text-align: left;
      align-self: flex-start;
      justify-content: flex-start;
      min-width: 150px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 1.5rem;
      h1 {
        font-weight: 800;
        margin-bottom: 0.2rem;
      }
      h3 {
        font-weight: 500;
      }
    }
    .bentext_right {
      display: flex;
      flex-direction: column;
      text-align: left;
      max-width: 500px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 1.5rem;
      .bolder {
        font-weight: 600;
        margin-bottom: 0.2rem;
      }
    }
  }
}
.tab_ul {
  li {
    a {
      color: $DarkBlue !important;
      font-weight: 600;
      border-bottom-width: 3px;
    }
  }
  .is-active {
    a {
      border-bottom-color: $LightBlue;
    }
  }
}
.tabs {
  overflow: auto;
  
  padding-left: 1rem;
  padding-right: 1rem;
  justify-self: flex-start;
}
.tab_content {
  max-width: 800px;
  min-height: 10rem;
  .content {
    text-align: left;
  }
}
#Sec_Team {
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left:2rem;
  padding-right: 2rem;
  background: $Blue;
  .team_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    img{
      min-width: 60%;
      max-width: 600px;
      object-fit: contain;
    }
    .text_container{
      margin-top:3rem;
      margin-bottom: 3rem;
      margin-left: 2rem;
      margin-right: 2rem;
      display: flex;
      min-width: 20rem;
      height: min(30vw,20vh);
      max-width: 600px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      padding:0 5%;
      h3{
        display: block;
        color: white;
      }
      .team_text_one{
        align-self: flex-start;
        justify-self: flex-start;
      }
      .team_text_two{
        align-self: center;
        justify-self: center;
      }
      .team_text_three{
        align-self: flex-end;
        justify-self: flex-end;
        margin-bottom: 0;
      }
    }
  }
  
}
</style>
